<template>
    <div class="grid">
        <div class="field col-12 flex flex-column gap-3">
            <div
                v-for="(perguntaForm, index) in _perguntasForm"
                :key="index"
                class="bg-bluegray-50 p-3"
                :class="{ 'bg-white border-1 border-bluegray-100 border-round-xl': Boolean(numeroPerguntaPai) }"
                :draggable="!numeroPerguntaPai"
                @dragstart="dragStart"
                @dragover.prevent
                @dragenter="dragEnter"
                @dragleave="dragLeave"
                @dragend="dragEnd"
                @drop="dragFinish"
            >
                <div class="mb-3">
                    <div class="mt-2 flex align-items-center">
                        <span class="mr-2 font-normal" :style="{ fontSize: '16px' }">{{ obterNumeroPergunta(index) }}</span>
                        <div :style="{ width: '50%' }">
                            <Dropdown
                                id="pergunta"
                                class="selects"
                                :model-value="_perguntasForm[index].id"
                                :options="perguntas"
                                :filter="true"
                                optionValue="id"
                                optionLabel="pergunta"
                                placeholder="Selecione..."
                                :class="{ 'p-invalid': submitted && !perguntaForm.id }"
                                @update:model-value="onAddPergunta($event, index)"
                            >
                                <template #option="slotProps">
                                    <div>
                                        <div
                                            :style="{
                                                opacity: isPerguntaUtilizada(slotProps.option.id) ? 0.3 : 1
                                            }"
                                        >
                                            {{ slotProps.option.pergunta }}
                                        </div>
                                    </div>
                                </template>
                            </Dropdown>
                            <small class="p-error" v-if="submitted && !perguntaForm.id">Pergunta é obrigatória.</small>
                        </div>
                        <div class="field-checkbox m-3 flex align-items-center gap-2">
                            <Checkbox :value="perguntaForm.obrigatorio" v-model="perguntaForm.obrigatorio" :binary="true" />
                            <span>Obrigatório</span>
                        </div>
                        <Button
                            v-if="_perguntasForm.length > 1 || numeroPerguntaPai"
                            icon="pi pi-times"
                            class="flex-shrink-0 ml-2 p-button-rounded p-button-danger p-button-text"
                            @click="removerPergunta($event, index)"
                        />
                        <span class="flex-grow-1 flex justify-content-end">
                            <Tag v-if="numeroPerguntaPai" class="bg-orange-200 text-bluegray-800" value="Condicionada" />
                        </span>
                    </div>
                </div>

                <div v-if="_perguntasForm[index]" class="flex flex-column gap-2">
                    <div v-for="(opcao, indexOpcao) of _perguntasForm[index]?.opcoes" :key="indexOpcao" class="mx-6 grid align-items-center">
                        <div class="flex align-items-center gap-3" :class="!limiteNiveisPerguntasAtingido ? 'col-5' : 'col-12'">
                            <component :is="obterComponenteOpcao(_perguntasForm[index])" disabled />
                            <InputText :model-value="opcao" disabled />
                        </div>
                        <!--TODO - ALTERAR !limiteNiveisPerguntasAtingido-->
                        <div v-if="false" class="col-7">
                            <Button
                                v-if="!verificarSeCondicionalAdicionada(_perguntasForm[index], indexOpcao)"
                                class="p-button-text w-fit"
                                label="Adicionar pergunta condicional"
                                icon="pi pi-plus"
                                @click="adicionarPerguntaCondicional(_perguntasForm[index], indexOpcao)"
                            />
                            <div v-else class="ml-4 flex align-items-center gap-2">
                                <span>
                                    {{ obterNumeroPerguntaCondicional(index, indexOpcao) }}
                                </span>

                                <div class="w-full">
                                    <Dropdown
                                        id="pergunta"
                                        class="selects"
                                        :model-value="obterPerguntaCondicional(index, indexOpcao).id"
                                        :options="perguntasNaoUtilizadasPrincipais"
                                        :filter="true"
                                        optionValue="id"
                                        optionLabel="pergunta"
                                        placeholder="Selecione..."
                                        :class="{ 'p-invalid': submitted && !perguntaForm.id }"
                                        @update:model-value="onChangePerguntaCondicional($event, index, indexOpcao)"
                                    >
                                        <template #option="slotProps">
                                            <div>
                                                <div
                                                    :style="{
                                                        opacity: isPerguntaUtilizada(slotProps.option.id) ? 0.3 : 1
                                                    }"
                                                >
                                                    {{ slotProps.option.pergunta }}
                                                </div>
                                            </div>
                                        </template>
                                    </Dropdown>
                                    <small class="p-error" v-if="submitted && !perguntaForm.id">Pergunta é obrigatória.</small>
                                </div>

                                <Button
                                    icon="pi pi-times"
                                    class="flex-shrink-0 ml-2 p-button-rounded p-button-danger p-button-text"
                                    @click="removerPerguntaCondicional($event, index, indexOpcao)"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-if="_perguntasForm[index]?.outros" class="mx-6 grid align-items-center">
                        <div class="col-5 flex align-items-center gap-3">
                            <component :is="obterComponenteOpcao(_perguntasForm[index])" disabled />
                            <InputText model-value="Outros" disabled />
                        </div>
                        <!--TODO - ALTERAR !limiteNiveisPerguntasAtingido-->
                        <div v-if="false" class="col-7">
                            <Button
                                v-if="!verificarSeCondicionalAdicionada(_perguntasForm[index], -1)"
                                class="p-button-text w-fit"
                                label="Adicionar pergunta condicional"
                                icon="pi pi-plus"
                                @click="adicionarPerguntaCondicional(_perguntasForm[index], -1)"
                            />
                            <div v-else class="ml-4 flex align-items-center gap-2">
                                <span>
                                    {{ obterNumeroPerguntaCondicional(index, -1) }}
                                </span>

                                <div class="w-full">
                                    <Dropdown
                                        v-if="_perguntasForm[index].children.at(-1)"
                                        id="pergunta"
                                        class="selects"
                                        :model-value="obterPerguntaCondicional(index, -1).id"
                                        :options="perguntasNaoUtilizadasPrincipais"
                                        :filter="true"
                                        optionValue="id"
                                        optionLabel="pergunta"
                                        placeholder="Selecione..."
                                        :class="{ 'p-invalid': submitted && !perguntaForm.id }"
                                        @update:model-value="onChangePerguntaCondicional($event, index, -1)"
                                    >
                                        <template #option="slotProps">
                                            <div>
                                                <div
                                                    :style="{
                                                        opacity: isPerguntaUtilizada(slotProps.option.id) ? 0.3 : 1
                                                    }"
                                                >
                                                    {{ slotProps.option.pergunta }}
                                                </div>
                                            </div>
                                        </template>
                                    </Dropdown>
                                    <small class="p-error" v-if="submitted && !perguntaForm.id">Pergunta é obrigatória.</small>
                                </div>

                                <Button
                                    icon="pi pi-times"
                                    class="flex-shrink-0 ml-2 p-button-rounded p-button-danger p-button-text"
                                    @click="removerPerguntaCondicional($event, index, -1)"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="_perguntasForm[index]?.children?.length" class="ml-7 mr-4">
                    <GerenciadorPerguntas
                        v-model:perguntas-form="_perguntasForm[index].children"
                        :contexto-geral="props.contextoGeral"
                        :perguntas="perguntasNaoUtilizadas"
                        :submitted="submitted"
                        :numero-pergunta-pai="obterNumeroPergunta(index)"
                    />
                </div>
            </div>

            <div v-if="podeAddPergunta" class="w-fit">
                <Button
                    icon="pi pi-plus font-semibold"
                    label="Adicionar pergunta"
                    class="p-button-text m-0 font-semibold text-lg"
                    @click="() => _perguntasForm.push({ id: null, descricao: '' })"
                />
            </div>
        </div>
    </div>

    <ConfirmPopup group="headless">
        <template #container="{ message, acceptCallback, rejectCallback }">
            <div class="flex flex-column gap-3 border-round p-3 max-w-30rem">
                <div class="font-semibold text-lg">{{ message.header }}</div>
                <div class="font-medium">{{ message.message }}</div>
                <div class="flex justify-content-end align-items-center gap-2 mt-3">
                    <Button label="Cancelar" outlined @click="rejectCallback" severity="danger" text></Button>
                    <Button label="Excluir" @click="acceptCallback" severity="danger"></Button>
                </div>
            </div>
        </template>
    </ConfirmPopup>

    <ConfirmPopup group="headless-alterar">
        <template #container="{ message, acceptCallback, rejectCallback }">
            <div class="flex flex-column gap-3 border-round p-3 max-w-30rem">
                <div class="font-semibold text-lg">{{ message.header }}</div>
                <div class="font-medium">{{ message.message }}</div>
                <div class="flex justify-content-end align-items-center gap-2 mt-3">
                    <Button label="Cancelar" outlined @click="rejectCallback" severity="danger" text></Button>
                    <Button label="Alterar" @click="acceptCallback" severity="danger"></Button>
                </div>
            </div>
        </template>
    </ConfirmPopup>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from 'vue';
import { useConfirm } from 'primevue/useconfirm';

const props = defineProps({
    submitted: {
        type: Boolean,
        required: true
    },
    perguntas: {
        type: Array,
        required: true
    },
    contextoGeral: {
        type: Array,
        required: true
    },
    perguntasForm: {
        type: Array,
        required: true
    },
    podeAddPergunta: {
        type: Boolean,
        default: false
    },
    numeroPerguntaPai: {
        type: String,
        default: null
    }
});

const emit = defineEmits(['update:form', 'update:perguntasForm']);

const _perguntasForm = computed({
    get: () => props.perguntasForm,
    set: (value) => emit('update:perguntasForm', value)
});

const perguntasNaoUtilizadas = computed(() => {
    return props.perguntas.filter((pergunta) => {
        if (!_perguntasForm.value.some((item) => Boolean(item))) return true;
        return !_perguntasForm.value?.some((item) => item.id === pergunta.id);
    });
});

const perguntasNaoUtilizadasPrincipais = computed(() => {
    return props.perguntas.filter((pergunta) => !_perguntasForm.value?.some((item) => item.id === pergunta.id));
});

function obterNumeroPergunta(indicePergunta) {
    const prefixo = props.numeroPerguntaPai ? `${props.numeroPerguntaPai}.` : '';
    return `${prefixo}${indicePergunta + 1}`;
}

function obterNumeroPerguntaCondicional(indicePergunta, indiceOpcao) {
    const prefixo = obterNumeroPergunta(indicePergunta);
    const indiceCondicional = _perguntasForm.value[indicePergunta].children.findIndex((item) => item.indiceOpcaoOrigem === indiceOpcao);
    return `${prefixo}.${indiceCondicional + 1}`;
}

const dragging = ref(-1);

const id = Math.random().toString(36).substring(2, 9);

function dragStart(which, ev) {
    ev.dataTransfer.setData('Text', id);
    ev.dataTransfer.dropEffect = 'move';
    dragging.value = which;
}

function dragEnter(ev) {
    if (ev.clientY > ev.target.height / 2) {
        ev.target.style.marginBottom = '10px';
    } else {
        ev.target.style.marginTop = '10px';
    }
}

function dragLeave(ev) {
    ev.target.style.marginTop = '2px';
    ev.target.style.marginBottom = '2px';
}

function dragEnd() {
    dragging.value = -1;
}

function dragFinish(to, ev) {
    moveItem(dragging.value, to);
    ev.target.style.marginTop = '2px';
    ev.target.style.marginBottom = '2px';
}

function moveItem(from, to) {
    if (to === -1) {
        removeItemAt(from);
    } else {
        _perguntasForm.value.splice(to, 0, _perguntasForm.value.splice(from, 1)[0]);
    }
}

function removeItemAt(index) {
    _perguntasForm.value.splice(index, 1);
}

function onAddPergunta(idPergunta, indicePergunta) {
    if (_perguntasForm.value[indicePergunta]?.children?.length > 0) {
        confirm.require({
            target: event.currentTarget,
            group: 'headless-alterar',
            header: 'Essa pergunta possui perguntas condicionais. Todas as perguntas condicionais serão removidas também. Esta ação não poderá ser desfeita.',
            accept() {
                applyPerguntaChange(idPergunta, indicePergunta);
            }
        });
    } else {
        applyPerguntaChange(idPergunta, indicePergunta);
    }
}

function applyPerguntaChange(idPergunta, indicePergunta) {
    if (isPerguntaUtilizada(idPergunta)) return;

    _perguntasForm.value[indicePergunta] = {
        ..._perguntasForm.value[indicePergunta],
        ...obterPerguntaPorId(idPergunta),
        children: undefined
    };
    _perguntasForm.value = [..._perguntasForm.value];
}

function isPerguntaUtilizada(id) {
    return verificarPerguntaUtilizadaEmTodoContexto(props.contextoGeral, id);
}

function verificarPerguntaUtilizadaEmTodoContexto(perguntas, id) {
    for (const pergunta of perguntas) {
        if (pergunta.id === id) return true;
        if (pergunta.children && verificarPerguntaUtilizadaEmTodoContexto(pergunta.children, id)) {
            return true;
        }
    }
    return false;
}

const confirm = useConfirm();

function removerPergunta(event, index) {
    const getMensagem = () => {
        if (_perguntasForm.value[index].children?.length) {
            return 'Essa pergunta possui perguntas condicionais. Todas as perguntas condicionais serão removidas também. Esta ação não poderá ser desfeita.';
        }
        return 'Esta ação não poderá ser desfeita.';
    };

    confirm.require({
        target: event.currentTarget,
        group: 'headless',
        header: 'Você tem certeza que deseja remover esta pergunta?',
        message: getMensagem(),
        accept() {
            removeItemAt(index);
        }
    });
}

function onChangePerguntaCondicional(idPergunta, indicePergunta, indiceOpcao) {
    const indiceASerAlterado = buscarIndiceASerManipulado(indicePergunta, indiceOpcao);

    if (isPerguntaUtilizada(idPergunta)) return;

    _perguntasForm.value[indicePergunta].children[indiceASerAlterado] = {
        ..._perguntasForm.value[indicePergunta].children[indiceASerAlterado],
        ...obterPerguntaPorId(idPergunta),
        children: undefined
    };
}

function removerPerguntaCondicional(event, indicePergunta, indiceOpcao) {
    const indiceASerRemovido = buscarIndiceASerManipulado(indicePergunta, indiceOpcao);

    const getMensagem = () => {
        if (_perguntasForm.value[indicePergunta].children[indiceASerRemovido].children?.length) {
            return 'Essa pergunta possui perguntas condicionais. Todas as perguntas condicionais serão removidas também. Esta ação não poderá ser desfeita.';
        }
        return 'Esta ação não poderá ser desfeita.';
    };

    confirm.require({
        target: event.currentTarget,
        group: 'headless',
        header: 'Você tem certeza que deseja remover esta pergunta?',
        message: getMensagem(),
        accept() {
            const copia = [..._perguntasForm.value[indicePergunta].children];
            copia.splice(indiceASerRemovido, 1);
            _perguntasForm.value[indicePergunta].children = copia;
        }
    });
}

function obterPerguntaCondicional(indicePergunta, indiceOpcao) {
    const indiceASerBuscado = buscarIndiceASerManipulado(indicePergunta, indiceOpcao);
    return _perguntasForm.value[indicePergunta].children[indiceASerBuscado];
}

function buscarIndiceASerManipulado(indicePergunta, indiceOpcao) {
    return _perguntasForm.value[indicePergunta].children.findIndex((item) => item.indiceOpcaoOrigem === indiceOpcao);
}

function obterPerguntaPorId(id) {
    return props.perguntas.find((pergunta) => pergunta.id === id);
}

function obterComponenteOpcao(pergunta) {
    switch (pergunta.tipo) {
        case 'CB':
            return 'RadioButton';
        case 'ME':
            return 'Checkbox';
        default:
            return undefined;
    }
}

function adicionarPerguntaCondicional(pergunta, indiceOpcaoOrigem) {
    if (!pergunta.children) {
        pergunta.children = [];
    }
    pergunta.children.push({
        id: null,
        descricao: '',
        indiceOpcaoOrigem
    });
}

function verificarSeCondicionalAdicionada(pergunta, indiceOpcaoOrigem) {
    return pergunta.children?.some((item) => item.indiceOpcaoOrigem === indiceOpcaoOrigem);
}

const limiteNiveisPerguntasAtingido = computed(() => {
    if (!props.numeroPerguntaPai) return false;
    const limiteNiveis = 3;
    return props.numeroPerguntaPai.split('.').length + 1 >= limiteNiveis;
});
</script>

<style scoped lang="scss">
.selects {
    width: 100%;
    border-radius: 6px;
}
</style>
