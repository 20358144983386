<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/questionarios-sesmt">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/questionarios-sesmt"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <h5 class="mb-4">{{ title }}</h5>
                    <div class="">
                        <div class="grid">
                            <div class="field field-checkbox col-12">
                                <label for="ativo" class="mr-2 ml-0">Ativo</label>
                                <InputSwitch id="ativo" v-model="form.ativo" />
                            </div>
                            <div class="field col-6">
                                <div class="field col-6">
                                    <label for="name">Nome do questionário</label>
                                    <InputText
                                        v-model.trim="form.nome"
                                        required="true"
                                        autofocus
                                        autocomplete="off"
                                        placeholder="Informe o nome"
                                        :class="{ 'p-invalid': submitted && !form.nome }"
                                    />
                                    <small class="p-error" v-if="submitted && !form.nome">Nome é obrigatório.</small>
                                </div>
                                <div class="field col-6">
                                    <label for="tipo">Tipo</label>
                                    <Dropdown
                                        id="tipo"
                                        class="selects"
                                        v-model="form.tipo"
                                        :options="tipos"
                                        required="true"
                                        optionValue="value"
                                        optionLabel="label"
                                        placeholder="Selecione..."
                                        :class="{ 'p-invalid': submitted && !form.tipo }"
                                    />
                                    <small class="p-error" v-if="submitted && !form.tipo">Tipo é obrigatório.</small>
                                </div>
                            </div>
                            <div class="field col-6">
                                <label for="customers">Clientes</label>
                                <PickList
                                    v-model="clientes"
                                    v-model:modelValue="clientes"
                                    dataKey="id"
                                    :showSourceControls="false"
                                    :showTargetControls="false"
                                    :striped-rows="true"
                                >
                                    <template #sourceheader>
                                        <div>Disponíveis</div>
                                        <br />
                                        <InputText placeholder="Pesquise pelo cliente" @input="(e) => onFilterClientes(e)" />
                                    </template>
                                    <template #targetheader>{{ clientes[1]?.length > 0 ? 'Selecionados' : 'Selecionados (Todos)' }} </template>
                                    <template #item="slotProps">
                                        {{ slotProps.item.name }}
                                    </template>
                                </PickList>
                            </div>
                        </div>

                        <GerenciadorPerguntas
                            v-model:perguntas-form="perguntasForm"
                            :contexto-geral="perguntasForm"
                            :perguntas="perguntas"
                            :submitted="submitted"
                            pode-add-pergunta
                        />

                        <div class="flex flex-row gap-3">
                            <div class="field-checkbox">
                                <InputSwitch v-model="form.inserirAnexo" inputId="inserirAnexo" />
                                <label for="inserirAnexo">Inserir anexo</label>
                            </div>
                            <div>
                                <div class="field-checkbox">
                                    <Checkbox
                                        id="anexoObrigatorio"
                                        :disabled="!form.inserirAnexo"
                                        :value="form.anexoObrigatorio"
                                        v-model="form.anexoObrigatorio"
                                        :binary="true"
                                    />
                                    <label :class="{ 'text-500': !form.inserirAnexo }" for="anexoObrigatorio">Obrigatório</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import SesmtService from '../../services/SesmtService';
import { getClientSesmt } from '@/services/http-sesmt';
import EnumTipoPergunta from '../../enums/EnumTipoPergunta';
import GerenciadorPerguntas from '@/pages/questionarios-sesmt/GerenciadorPerguntas.vue';
import FormularioFormatado from '@/pages/questionarios-sesmt/formularioFormatado';

export default {
    components: { GerenciadorPerguntas },
    data() {
        return {
            form: {
                ativo: true,
                anexoObrigatorio: false,
                inserirAnexo: false
            },
            submitted: false,
            clientes: [],
            perguntas: [],
            perguntasForm: [{ id: null }],
            tiposPergunta: EnumTipoPergunta,
            tipos: [
                {
                    value: 'IN',
                    label: 'Inicial'
                },
                {
                    value: 'AM',
                    label: 'Ambiente'
                }
                // Removido temporariamente pois ainda não foi desenvolvida essa parte
                // {
                //     value: 'IF',
                //     label: 'Identificação e caracterização de perigo/fator de risco'
                // }
            ]
        };
    },
    created() {
        this.service = new SesmtService('/questionario-sesmt');
        this.$serviceCliente = new SesmtService('/customers/findall-semfiltro-tenant');
        this.$servicePergunta = new SesmtService('/pergunta-sesmt/findall/ativo');
    },
    async mounted() {
        await this.loadPerguntas();
        if (this.$route?.query?.id) {
            const { data } = await getClientSesmt().get(`/questionario-sesmt/${this.$route?.query?.id}`);
            await this.onLoadData(data);
        }
    },
    watch: {
        'form.inserirAnexo'(newValue) {
            if (!newValue) {
                this.form.anexoObrigatorio = false;
            }
        }
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar questionário SESMT' : 'Adicionar questionário SESMT';
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;

            if (!this.form.inserirAnexo) {
                this.form.anexoObrigatorio = false;
            }

            const formularioFormatado = new FormularioFormatado({
                formQuestionario: this.form,
                perguntasForm: this.perguntasForm,
                clientesSelecionados: this.clientes[1]
            });

            this.form = { ...formularioFormatado };
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }

            return cbSuccess();
        },
        async onLoadData(data) {
            this.clientes = [[], []];

            this.form = {
                ...data
            };

            this.perguntasForm = data.questionarioSESMTPergunta?.map((qp) => {
                return { ...qp.perguntaSESM, children: qp.children };
            });
            this.clientes = [
                [],
                [
                    ...(data.questionarioSESMTCliente?.map((qc) => {
                        return qc.cliente;
                    }) || [])
                ]
            ];
        },
        async onLoadDataEdit(data) {
            this.clientes = [
                [],
                [
                    ...(data.questionarioSESMTCliente?.map((qc) => {
                        return qc.cliente;
                    }) || [])
                ]
            ];

            this.form = data;

            const mapChildren = (children) => {
                if (!children) return;
                return children.map((child) => {
                    return {
                        ...child,
                        ...child.perguntaSESMT,
                        children: mapChildren(child.children)
                    };
                });
            };

            const questionarioSESMTPergunta = data.questionarioSESMTPergunta?.map((qp) => {
                return {
                    ...qp.perguntaSESMT,
                    children: mapChildren(qp.children)
                };
            });

            this.perguntasForm = questionarioSESMTPergunta.map((perguntas) => {
                const obrigatorio = data?.questionarioSESMTPergunta?.some(
                    (questionarioSESMTPergunta) =>
                        questionarioSESMTPergunta.idPerguntaSESMT === perguntas?.id && questionarioSESMTPergunta.obrigatorio
                );
                return {
                    ...perguntas,
                    obrigatorio
                };
            });
        },
        hasError() {
            if (!this.form.nome) return true;
            if (!this.form.tipo) return true;
            if (this.perguntasForm.length <= 0 || this.perguntasForm.find((pergunta) => pergunta.id === null)) return true;
        },
        onError(error) {
            if (error.response?.data?.statusCode === 422) {
                this.$toast.add({ severity: 'error', summary: error.response?.data?.message || error.message, life: 3000 });
            }
        },
        async loadCustomers(event) {
            const companyId = this.form.companies?.map((c) => c.id);
            const { data } = await this.$serviceCliente.findAll({ filter: event?.target?.value, companyId });

            const clientesSelecionados = this.clientes[1];
            if (clientesSelecionados?.length > 0) {
                this.clientes = [data.items, clientesSelecionados];
            } else {
                this.clientes = [data.items, []];
            }
        },
        async loadPerguntas(event) {
            const companyId = this.form.companies?.map((c) => c.id);
            const { data } = await this.$servicePergunta.findAll({ filter: event?.value, companyId });
            this.perguntas = data
                .sort((a, b) => {
                    return a.descricao - b.descricao;
                })
                .map((item) => {
                    const tipo = this.tiposPergunta.find((tipoPergunta) => tipoPergunta.value === item.tipo)?.label;

                    return {
                        ...item,
                        pergunta: `${item.descricao} - ${tipo}`
                    };
                });
        },
        onFilterClientes(e) {
            if (e.target.value?.length >= 3) {
                this.loadCustomers(e);
            } else if (e.target.value?.length === 0) {
                const clientesSelecionados = this.clientes[1];
                if (clientesSelecionados?.length > 0) {
                    this.clientes = [[], clientesSelecionados];
                } else {
                    this.clientes = [[], []];
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
.multiSelect {
    padding: 0.25rem 0.5rem;
    border-radius: 6px;
}

.selects-cliente {
    border-radius: 6px;
    padding: 0.25rem 0.5rem;
}

.selects {
    border-radius: 6px;
}

.chips {
    height: 37px;
}

.todo-item {
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 14px 8px;
    margin-bottom: 3px;
    background-color: #fff;
    box-shadow: 1px 2px 2px #ccc;
    font-size: 22px;
}

.p-dropdown-item {
    color: red;
}
</style>
