export default class FormularioFormatado {
    constructor({ formQuestionario, perguntasForm, clientesSelecionados }) {
        this.id = formQuestionario.id;
        this.nome = formQuestionario.nome;
        this.tipo = formQuestionario.tipo;
        this.clientes = clientesSelecionados?.map(({ id }) => {
            return { id };
        });
        this.perguntas = this.formatarPerguntas(perguntasForm);
        this.anexoObrigatorio = formQuestionario.inserirAnexo ? formQuestionario.anexoObrigatorio : false;
        this.inserirAnexo = formQuestionario.inserirAnexo;
        this.ativo = formQuestionario.ativo;
        this.questionarioSESMTPergunta = this.formatarQuestionarioSESMTPergunta(formQuestionario.questionarioSESMTPergunta, perguntasForm);
    }

    formatarPerguntas(perguntasFormArray) {
        return perguntasFormArray.map((perguntasForm, index) => {
            const { id, obrigatorio, mensagemAjuda, children, indiceOpcaoOrigem } = perguntasForm;
            return {
                id,
                ordem: index + 1,
                obrigatorio,
                mensagemAjuda,
                children,
                indiceOpcaoOrigem
            };
        });
    }

    formatarQuestionarioSESMTPergunta(questionarioSESMTPergunta, perguntas) {
        return questionarioSESMTPergunta?.map((item) => {
            return {
                ...item,
                obrigatorio: perguntas.some((pergunta) => pergunta.id === item.idPerguntaSESMT)
            };
        });
    }
}
